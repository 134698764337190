<template>
    <div class="info-box-container">
        <h3>
            {{ card === "Attributes" ? title : $t(`ProductionProgress.InfoTab.${card}.${title}`) }}
        </h3>
        <div class="info-container">
            <div
                :class="{ 'ellipsis-info': data.truncate }"
                v-b-tooltip.hover="{
                    customClass: `${
                        data.truncate && windowWidth > 768 ? 'info-order-tooltip' : 'display-none'
                    }`
                }"
                :title="data.value"
            >
                <span>{{
                    isDate
                        ? getFormatedDate(data.value)
                        : getFormatResources(data.value) | emptyToDash
                }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from "moment";
    import { VBTooltip } from "bootstrap-vue";

    export default {
        filters: {
            emptyToDash(value) {
                if (!value) {
                    return "-";
                }
                return value;
            }
        },
        directives: {
            "b-tooltip": VBTooltip
        },
        props: {
            card: {
                type: "Order" | "Operation" | "Attributes",
                default: "",
                required: true
            },
            title: {
                type: String,
                default: "",
                required: true
            },
            isDate: {
                type: Boolean,
                default: false,
                required: true
            },
            data: {
                type: Object,
                default: () => {},
                required: true
            }
        },
        data: () => ({
            windowWidth: window.innerWidth
        }),
        methods: {
            getFormatedDate(date) {
                if (!date) return "";

                const language = this.$cookies.get("userInfo").language;
                if (language == "pt-BR") return moment(date).format("DD-MM-YYYY HH:mm");

                return moment(date).format("MM-DD-YYYY HH:mm");
            },
            getFormatResources(value) {
                if (Array.isArray(value)) {
                    return value.map((e) => e.name).join(",");
                }

                return value;
            }
        }
    };
</script>

<style lang="scss">
    .display-none {
        display: none;
    }

    .info-order-tooltip {
        margin: 0;
        z-index: 99999999;
        max-height: none !important;

        .tooltip-inner {
            max-width: 516px !important;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            text-align: left;

            padding: 8px;
            background-color: #362f2b;
            border-radius: 6px;
            border: none;
            outline: none;
        }

        .arrow {
            &::before {
                border-top-color: #362f2b;
                border-bottom-color: #362f2b;
            }
        }
    }
</style>

<style lang="scss" scoped>
    .info-box-container {
        display: flex;
        flex-direction: column;

        h3 {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #998f8a;
            margin: 0;
        }

        .info-container {
            display: flex;
            overflow: hidden;
            line-height: 0;

            span {
                font-weight: 600;
                font-size: 22px;
                line-height: 30px !important;
                color: #4c4541;
            }
            .ellipsis-info {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 100%;

                font-weight: 600;
                font-size: 22px;
                color: #4c4541;
            }
        }
    }

    @media (max-width: 600px) {
        .info-box-container {
            flex-direction: row !important;
            justify-content: space-between;
            align-items: center;
            gap: 0 16px;

            h3 {
                max-width: 40%;
            }
            .info-container {
                max-width: 60%;
            }
        }
    }

    @media (max-width: 425px) and (min-width: 330px) {
        .info-box-container {
            h3 {
                max-width: 50% !important;
            }

            .info-container {
                span {
                    max-width: 50% !important;
                }
            }
        }
    }

    @media (max-width: 425px) {
        .info-box-container {
            flex-direction: row !important;
            justify-content: space-between;
            align-items: center;
            gap: 0 16px;

            h3 {
                font-size: 12px !important;
                line-height: 16px !important;
            }

            .info-container {
                span {
                    font-size: 14px !important;
                    line-height: 20px !important;
                }
            }
        }
    }

    @media (max-width: 330px) {
        .info-box-container {
            gap: 0 !important;
            h3 {
                max-width: 50% !important;
            }

            .info-container {
                span {
                    max-width: 50% !important;
                }
            }
        }
    }
</style>
